<template>
  <div class="container">
    <nav class="navbar">
      <div class="navbar-left">
        <span>Ciao, {{ accountName }}</span>
      </div>
      <div class="navbar-right">
        <button @click="handleLogout" class="logout-button">Logout</button>
      </div>
    </nav>

    <div class="content">
      <h1>Benvenuto nel Flight Tracker!</h1>
      <p>Inserisci il numero di volo per cercare i dettagli:</p>
      <div class="search-bar">
        <input type="text" v-model="flightNumber" placeholder="Numero di volo" class="search-input" />
        <button @click="searchFlight" class="search-button">Cerca</button>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from "@/auth.js";

export default {
  name: "SuccessPage",
  data() {
    return {
      accountName: "Utente",  // Default name
      flightNumber: "",  // Input for flight number
    };
  },
  mounted() {
    const storedAccountName = sessionStorage.getItem("accountName");
    if (storedAccountName) {
      this.accountName = storedAccountName;
    }
  },
  methods: {
    // Fetch user profile information
    async fetchUserProfile() {
      try {
        const response = await fetch("https://www.rimpici.it/api/profile", {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          this.accountName = data.user.firstName; // Assuming "nome" is the user's name
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    },
    
    // Logout user
    async handleLogout() {
      const result = await logout();
      if (result) {
        this.$router.push('/'); // Redirect to home page
      }
      sessionStorage.removeItem("accountName");
    },
    
    // Search for flight by flight number
    async searchFlight() {
      if (!this.flightNumber) {
        alert("Per favore inserisci un numero di volo.");
        return;
      }
      try {
        const response = await fetch(`https://www.rimpici.it/api/flights?number=${this.flightNumber}`, {
          method: "GET",
          credentials: "include",
        });
        if (!response.ok) throw new Error("Errore durante la ricerca del volo.");
        
        const data = await response.json();
        alert(`Dettagli volo: ${JSON.stringify(data)}`);
      } catch (error) {
        console.error("Errore:", error);
        alert("Impossibile trovare il volo.");
      }
    }
  }
};
</script>

<style scoped>
/* Custom styles for dark theme and layout */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: #121212;
  color: #ffffff;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #1f1f1f;
  position: fixed;
  top: 0;
  left: 0;
}

.search-bar {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.search-input {
  width: 250px;
  padding: 10px;
  background-color: #1f1f1f;
  color: white;
}

.search-button {
  background-color: #4caf50;
  color: white;
}
</style>
