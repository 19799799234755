const TOKEN_KEY = 'authToken';

export function saveToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function isAuthenticated() {
  return !!getToken();
}

export function logout() {
  localStorage.removeItem(TOKEN_KEY);
  // Optionally, also remove sessionStorage if you store session info
  sessionStorage.removeItem("accountName");
}
