import { createRouter, createWebHistory } from 'vue-router';
import { isAuthenticated } from '../auth'; // Function to check if the user is logged in

import HomeView from '../views/HomeView.vue';
import SuccessPage from '../views/SuccessPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next('/success');
      } else {
        next();
      }
    },
  },
  {
    path: '/success',
    name: 'Success',
    component: SuccessPage,
    meta: { requiresAuth: true }, // This page requires authentication
  },
];

// Set up the router with history mode
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Guard for routes that require authentication
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isAuthenticated()) {
    next('/');  // Redirect to the home page if the route requires auth and user is not authenticated
  } else {
    next();  // Proceed if the user is authenticated or route does not require auth
  }
});

export default router;
