<template>
  <div class="container">
    <div class="app-name">
      WORLDFLIGHTTRACKER
    </div>
    <div class="login-container">
      <h2>{{ isLogin ? 'Accedi' : 'Registrati' }}</h2>
      <form @submit.prevent="handleSubmit">
        <input v-if="!isLogin" type="text" v-model="nome" placeholder="Nome" class="input-field" required />
        <input v-if="!isLogin" type="text" v-model="cognome" placeholder="Cognome" class="input-field" required />
        <input v-if="!isLogin" type="number" v-model="eta" placeholder="Età" class="input-field" required />
        <input v-if="!isLogin" type="text" v-model="aeroporto_preferenza" placeholder="Aeroporto Preferito"
          class="input-field" />
        <input type="email" v-model="email" placeholder="Email" class="input-field" required />
        <input type="password" v-model="password" placeholder="Password" class="input-field" required />
        <button type="submit" class="login-button">
          {{ isLogin ? 'Login' : 'Registrati' }}
        </button>
        <button @click.prevent="toggleMode" class="toggle-button">
          {{ isLogin ? 'Passa a Registrati' : 'Passa a Login' }}
        </button>
        <p v-if="message" :class="{ 'error-message': isError, 'success-message': !isError }">
          {{ message }}
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { login, isAuthenticated } from "@/auth.js";

export default {
  data() {
    return {
      isLogin: true,
      nome: "",
      cognome: "",
      eta: "",
      aeroporto_preferenza: "",
      email: "",
      password: "",
      message: "",
      isError: false,
    };
  },
  methods: {
    toggleMode() {
      this.isLogin = !this.isLogin;
      this.clearForm();
      this.message = "";
    },
    clearForm() {
      this.nome = "";
      this.cognome = "";
      this.eta = "";
      this.aeroporto_preferenza = "";
      this.email = "";
      this.password = "";
      this.message = "";  // Reset message on form clear
      this.isError = false;  // Reset error state
    },
    async handleSubmit() {
      this.isLogin ? await this.loginUser() : await this.registerUser();
    },
    async registerUser() {
      const userData = {
        firstName: this.nome,
        lastName: this.cognome,
        age: this.eta,
        email: this.email,
        password: this.password,
        preferredAirport: this.aeroporto_preferenza,
      };

      try {
        const response = await fetch("https://www.rimpici.it/api/register", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(userData),
        });

        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.error || `Errore HTTP! Status: ${response.status}`);
        }


        const data = await response.json();
        this.message = data.message || "Registrazione avvenuta con successo! Ora puoi accedere.";
        this.isError = false;
        this.toggleMode();
      } catch (error) {
        this.message = "Errore durante la registrazione: " + error.message;
        this.isError = true;
      }
    },
    async loginUser() {
      const loginData = {
        email: this.email,
        password: this.password,
      };

      try {
        const response = await fetch("https://www.rimpici.it/api/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
          credentials: "include",
        });

        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.error || `Errore HTTP! Status: ${response.status}`);
        }


        const data = await response.json();
        this.message = data.message || "Login effettuato con successo!";
        this.isError = false;

        login(data.token);
        sessionStorage.setItem("accountName", data.user.firstName);
        this.$router.push("/success");
      } catch (error) {
        this.message = "Errore nel login: " + error.message;
        this.isError = true;
      }
    },
    async checkSession() {
      try {
        const response = await fetch("https://www.rimpici.it/api/profile", {
          method: "GET",
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          this.message = `Bentornato, ${data.user.firstName}!`;
          this.isError = false;
          this.$router.push("/success");
        }
      } catch {
        // Ignore if not authenticated
      }
    },
  },
  async created() {
    if (isAuthenticated()) {
      this.$router.push("/success");
    }
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-color: #000;
  color: #fff;
  box-sizing: border-box;
}

.app-name {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  max-width: 350px;
}

.login-container {
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  max-width: 350px;
  width: 100%;
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  color: #fff;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
}

.login-button,
.toggle-button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
}

.login-button {
  background-color: #007bff;
}

.toggle-button {
  background-color: #555;
}

.error-message {
  color: #ff4d4f;
}

.success-message {
  color: #4caf50;
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 40px;
  }

  .app-name {
    margin-right: 40px;
    text-align: left;
  }

  .login-container {
    margin-left: 40px;
    text-align: left;
    width: auto;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    justify-content: center;
  }

  .app-name {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 15px;
  }

  .login-container {
    max-width: 100%;
    padding: 15px;
  }
}
</style>
